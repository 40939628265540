/* 英语 */
export default {
  emailRegister: 'E-mail登录',
  registerNotice: '未注册的用户将自动注册并登录',
  enterEmail: '请输入E-mail地址',
  enterPhone: '请输入电话号码',
  enterCode: '请输入验证码',
  getCode: '获取验证码',
  signIn: '立即登录',
  signPropt: '登录即表示您同意',
  policy: '隐私协议',
  agreement: '用户协议',
  emailRuleError: 'E-mail格式不正确',
  starcard: `M-Card会员权益`,
  more: `更多`,
  regisgerAndEnjoy: `注册享受精彩好剧`,

  describe: `M-Card是M-Plan推广系统的权利证明。 M-Card分为M-Card和M-Card Pro。成为M卡用户即可获得奖励。`,
  starReward: `M-Card奖励`,
  unlock: ` 解锁`,
  text1: `· 邀请奖励：直接邀请用户成为M卡可获得10%的奖励。`,
  text2: `· 版权推广奖励：直接邀请用户投资版权，即可获得受邀者每日收入的10%作为奖励。`,
  text3: `· 电影奖励：直接邀请用户成为影视VIP可获得20%奖励；直接邀请用户购买剧点数可以获得40%的奖励。`,
  starClu: `M-Team奖励`,
  text4: `咨询客服了解更多权益。`,
  copyright: `版权`,
  td1: `年化报酬率`,
  td2: `初始投资金额`,
  td3: `已完成/总计`,
  warning: `*每日收入会根据推广效果而有所波动。`,
  schedule: `众筹进展`,
  buynow: `立即购买`,
  download: `下载`,
  go: `前往`,
  android: `安卓`,
  apple: `App Store`,
  Characteristic: `特征`,
  Participation: `参与金额`,
  method: `付款方式`,
  Payamount: `付款金额`,
  jump: `支付`,
  name1: `短剧`,
  name2: `多种类型`,
  name3: `M-card会员`,
  name4: `推广收入`,
  describe1: `品质保证`,
  describe2: `想你所想`,
  describe3: `巨额补贴`,
  describe4: `最佳副业`,
  warning1: `内容不能为空`,
  success1: `绑定成功`,
  ends: `计时结束`,
  warning2: `您还没有登录`,

  experienceStar: `M-Card`,
  ExclusiveStar: `M-Card Pro`,
  noCard: `非M-Card`,
  aboutus: `About Us`,
  understand: `关于我们`,
  Snapshort: `${window.name} 团队:`,
  Innovators: `Innovators reshaping the`,
  industry: `film and television industry`,
  paragraph1_1: `In the ever-changing digital era, a group of developers passionate about film and television art are working to reshape the audience's viewing experience.`,
  paragraph1_2: `Our team, ${window.name}, takes innovation as its core and technology as its driver, and is leading the new trend in the film and television industry.`,
  paragraph1_3: `${window.name} is a mobile application focusing on short drama content. We carefully select high-quality short dramas to adapt to the fast-paced lifestyle of modern audiences.`,
  paragraph1_4: `Our app allows users to watch the latest and most popular short dramas anytime, anywhere, while providing rich personalized recommendations so that every user can find their favorite content.`,
  paragraph2_1: `Our team consists of a group of experienced and technically proficient developers who are proficient in film and television content production, big data analysis, artificial intelligence recommendation and other technologies.`,
  paragraph2_2: `This allows us to efficiently produce and update content while providing customized recommendations for each user.`,
  paragraph2_3: `Our vision is to allow every user to find their own fun and inspiration in the drama Gravity. We believe that through our efforts, ${window.name} will become a part of your life, allowing you to find a moment of peace and joy in your busy life.`,
  paragraph3_1: `Whether you're looking for light-hearted entertainment or a story that's thought-provoking, ${window.name} has you covered.`,
  paragraph3_2: `Let's explore the infinite possibilities of film and television art together.`,
  period: `M-Card`,
  Premier: `M-Card Pro`,
  Expiration: `结束日期:`,
  renew: `立即续订`,
  balance: `M-Card余额`,
  Income: `累计收入`,
  wallet: `转账至钱包`,
  daily: `每日任务`,
  nocompleted: `今日未完成`,
  code: `影视VIP`,
  Residue: `剩余兑换码`,
  reward: `今日收入`,
  type: `类型`,
  amount: `金额`,
  time: `时间`,
  none: `没有更多内容`,
  load: `加载中`,
  loadmore: `加载更多`,
  success2: `转入钱包成功`,
  five: `M5达人`,
  four: `M4达人`,
  Samsung: `M3达人`,
  two: `M2达人`,
  one: `M1达人`,
  recommendation: `邀请奖励`,
  rewards: `奖励`,
  taskRewards: `任务奖励`,
  starRewards: `版权推广奖励`,
  MovieRewards: `M-Card推广奖励`,
  annualized: `年化报酬率`,
  Initial: `初始投资金额`,
  CompletedTotal: `已完成/总计`,
  tishi: `*每日收入会根据推广效果而有所波动。`,
  Crowdfunding: `众筹进度:`,
  dividend: `众筹剩余额度`,
  Project: `项目名称`,
  Cumulative: `累计收入`,
  Dividend: `每日回报率`,
  investment: `投资金额`,
  name5: `版权`,
  name6: `持有`,
  title1: `进行中`,
  title2: `已结束`,
  title3: `参与历史`,
  title4: `收益详情`,
  text5: `参与时间`,
  text6: `收益时间`,
  text7: `累计收入`,
  text8: `支付金额`,

  remaining: `剩余任务:`,
  Claim: `领取任务截止日期:`,
  Submission: `提交截止日期:`,
  Completion: `完成时间:`,
  Completed: `已完成`,
  Complete: `完成`,
  error: `任务审核失败，请重新提交图片进行审核。`,
  warning3: `任务完成图片应显示当天的日期。`,
  sample: `示例`,
  Copywriting: `推广口号`,
  Claim2: `获取任务`,
  SubmitTask: `提交任务`,
  Waiting: `审核中`,
  Resubmit: `重新提交`,
  resubmit: `,请重新提交`,
  Expand: `扩展`,
  Recover: `返回`,
  Deadline: `截止时间:`,
  text9: `1.任务完成图片应显示推广口号。`,
  text10: `2.任务完成图像中的文字内容应显示当天的日期。`,
  success3: `下载成功`,
  error2: `下载失败`,
  success4: `复制成功`,
  warning4: `请上传任务完成图片。`,
  success5: `上传成功`,
  emaillogin: `E-mail登录`,
  warning5: `未注册的用户将自动注册并登录`,
  text11: `获取验证码`,
  Sign: `立即登录`,
  text12: `登录即表示您同意`,
  Privacy: `隐私协议`,
  Agreement: `用户协议`,
  warning6: `请输入E-mail地址`,
  warning7: `请输入正确的E-mail地址`,
  warning8: `验证码不能为空`,
  warning9: `请输入E-mail地址`,
  retrieve: `恢复`,
  Payment: `确认付款`,
  InformationFilling: `填写信息`,
  BalanceCombinationPayment: `混合支付`,
  AvailableBalance: `可用余额:`,
  will: `发送至`,
  setCode: ` 验证码`,
  BalanceDeduction: `扣除余额后仍需付款`,
  StarClusterLevel: `M-Team等级`,
  PersonalPerformance: `个人业绩`,
  StarClusterPerformance: `M-Team业绩`,
  memberNumber: `M-Team人数`,
  NumberStar: `M-Card人数`,
  NumberDirectly: `直接受邀者`,
  activeMembers: `活跃用户`,
  title_1: `登录`,
  title_2: `首页`,
  title_3: `钱包`,
  title_4: `M-Card`,
  title_5: `版权`,
  title_6: `团队`,
  title_7: `关于我们`,
  title_8: `团队收益`,
  title_9: `切换账户`,
  title_10: `退出`,
  title_11: `兑换码`,
  title_13: `IM通讯`,
  title_12: `交易市场`,

  interests: `M-Card收益`,
  log_fir: `请登录`,
  name7: `免费内容`,
  name8: `每日任务`,
  name9: `M-Card会员`,
  name10: `更多权益`,
  describe5: `获得免费影视VIP`,
  describe6: `巨额补贴`,
  describe7: `分享&邀请`,
  describe8: `解锁其他权益`,

  Invite: `分享&邀请`,
  invitation: `我的邀请码`,
  directly: `直接受邀者`,
  indirect: `间接受邀者`,
  Copy_link: `复制邀请链接`,
  Fill: `填写邀请码`,
  inv_code: `邀请码: `,
  Replicating: `复制成功`,
  error3: `复制失败`,
  error4: `粘贴失败:`,

  Apple_login: `Apple登录`,
  Google_login: `Google登录`,
  Email_login: `E-mail登录`,

  Commission: `佣金率:`,
  nolevel: `无M-Team等级`,
  community: `M-Team人数`,
  Community1: `直接推广业绩`,
  promoted: `今日邀请人数`,
  Promote: `版权推广奖励`,
  balance2: `余额`,
  Withdraw: `提现`,
  Transfer: `转账`,
  Interpersonal: `M-Team推广`,
  mixpayTips: `优先使用钱包余额`,
  noBalanceL: `钱包余额不足`,

  days: `天`,
  Selected: `已选择`,
  Select: `选择`,
  paid: `金额`,
  gopay: `支付`,
  warning10: `选择付款方式`,

  progress: `付款进行中`,
  cancel: `取消`,
  confirm: `确认`,
  Paid: `已付款`,
  warning11: `付款成功`,

  Remaining: `剩余兑换码:`,
  Exchange: `兑换列表`,
  Redemption: `兑换码`,
  Duration: `时间`,
  Period2: '兑换截止日期',
  State: `状态`,
  Function: `操作`,
  nouse: `未使用`,
  Used: `已使用`,
  Copy: `复制`,

  Wallet2: `钱包余额记录`,
  Variable: `金额(${window.currency})`,
  OrderID: `ID`,
  Balance3: `提现`,
  Balance5: `转账`,
  information: `填写信息`,
  Amount4: `提款金额`,
  Balance6: `转账金额`,
  Balance4: `余额:`,
  nomoney: `钱包余额不足`,
  crowdfunding: `参与版权`,

  title_name: `User Agreement`,
  t1: `This agreement shall be issued and come into effect on March 15, 2023, with the latest revision made on March 15, 2023.`,
  t2: `${window.region} ${window.company} Technology Co., Ltd. is the registered copyright owner of this software and enjoys the copyright of this software in accordance with the law. This user agreement (hereinafter referred to as 'this agreement') is a valid contract between ${window.company} Technology Co., Ltd. (hereinafter referred to as "${window.company} Technology", "us") and the user (also referred to as "you") regarding the installation and use of the client software product "Juyouli" (hereinafter referred to as "this software"). By clicking to confirm this agreement or choosing to accept it in any other way, you indicate that you have reached an agreement with ${window.company} Technology and agree to accept all the following provisions of this agreement. This software is operated by ${window.company} Technology and provides video services to you, enjoying rights, fulfilling obligations, and assuming legal responsibilities in accordance with the law.`,
  t3: `Once this agreement is changed, the software will prompt for modification on the software page. Once the modified usage agreement is displayed on the announcement page, it effectively replaces the original agreement. You should log in to this software at any time to check the latest protocols. Before using the various services provided by this software, you should carefully read this agreement. If you do not agree to the modification of this agreement, you may voluntarily cancel the services provided by this software. Otherwise, your use will be deemed as your acceptance of this agreement and the entire content modified at any time. `,
  t4: `1、 How do we collect and use your personal information`,
  t5: `Personal information refers to various types of information recorded electronically or in other ways that can individually or in combination with other information identify the identity of a specific natural person or reflect the activities of a specific natural person.`,
  t6: `${window.company} Technology and the registered copyright owner of this product will only collect and use your personal information for the purposes, methods, and scope described in this policy when providing services to you:`,
  t7: `1. Application Function 1: Become a Registered User`,
  t8: `When applying to become a registered user of this product, you need to provide the following information: your device information.`,
  t9: `The above information you provided authorizes us to continue using this service for you. When you cancel your account, we will stop using and delete the above information.`,
  t10: `2. Application function 2: Display operation logs within the application`,
  t11: `When you use our services, this product will record your operation logs, including browsing and clicking records, adding TV shows, watching TV shows, and participating in activities. The above information you provided authorizes us to continue using this service for you. When you cancel your account, we will stop using and delete the above information.`,
  t12: `3. Application Function Three: Subscription and Payment`,
  t13: `When you use this product, the records generated when ordering VIP membership and episode payment services, including subscription records and consumption records, will be stored on our server. The above information is necessary for product functionality. If you do not agree to our recording of the above information, we will not be able to provide you with episode purchase services.`,
  t14: `4. Application Function Four: Security Services`,
  t15: `To provide you with secure and stable services, ensure the security of your account and property, and prevent false login, in order to use the functions and services of this product normally, we may automatically record certain information when you use our product or service, including: device or software information: configuration information provided by your mobile device, web browser or other programs used to access our services, your IP address, software version information used by your mobile device, device identification code (IMEI, MAC address), device unique identifier (Android ID, OAID) information, and device serial number. If you do not agree to our recording of the aforementioned information, you may not be able to complete the risk control process and use our product. We may also automatically record whether you have installed Alipay APP. If you do not agree with us to record the above information, your normal use of this product will not be affected.`,
  t16: `5. Application Function 5: Enabling Permissions`,
  t17: `We promise not to exceed the scope or frequency of collecting your personal information, and never to collect or use information that is not necessary or has no reasonable application scenarios for the services of this product.`,
  t18: `When using this product, for the purpose of providing you with services, this product may request the following permissions for terminal devices in certain scenarios where you use the application. Of course, you can refuse to enable the following permissions, or turn off the following permissions for this product in the settings of your phone. Turning off the following permissions will not affect your use of other functions of this product:`,
  t19: `Access phone: By obtaining your phone status permission, we record your device identification code (i.e. IMEI or IMSI code) for anonymization processing, for advertising display and security verification;`,
  t20: `Access local storage: By enabling local storage permissions, download the episodes within this product to your phone's storage;`,
  t21: `Access photos: You can directly select photos or images from your phone to upload or provide proof when communicating with customer service;`,
  t22: `Access media content and files on your device: cache for reading and writing episode covers and event images, improving app usage fluency.`,
  t23: `Enable wireless data: You can achieve the networking functions required by this product by connecting to wireless or cellular networks;`,
  t24: `Access the installation program list: By obtaining your installation program list, we can confirm whether you have installed the products we promote, so that we can promptly distribute corresponding rewards to you.`,
  t25: `Turn on microphone and voice recognition: You can control the voice reading function and voice control function of this product through voice;`,
  t26: `Use clipboard: You can copy and paste customer service phone numbers, or choose not to use clipboard permissions.`,
  t27: `Obtain device network permissions for the purpose of using dictionaries, encyclopedias, and voice reading;`,
  t28: `Get rough location: When browsing certain advertisements, obtain rough location permission;`,
  t29: `Access search location: When browsing certain advertisements, it will obtain search location permission;`,
  t30: `Using a camera: When users provide feedback, they may gain permission to use the camera.`,
  t31: `2、 How do we use cookies and similar technologies`,
  t32: `Cookies and similar technologies are commonly used in the Internet. To ensure the normal operation of this platform and provide you with an easier access experience, we may store small data files called cookies on your mobile device. Cookies typically contain identifiers, site names, and some numbers and characters. With the help of cookies, we will record your login information and operation information in this product, so that we can store your usage habits and help determine the security status of your account, providing you with a more relaxed user experience and services.`,
  t33: `We will not use cookies for any purpose other than those stated in this policy. You can manage or deactivate cookies according to your preferences. For more information, please refer to AboutCookies. org. If cookies are deactivated, you may not be able to enjoy the best service experience, and our recommended content may also be less relevant to you.`,
  t34: `3、 How do we share, transfer, and publicly disclose your personal information`,
  t35: `（1） Sharing`,
  t36: `We will not share your personal information with any company, organization, or individual other than ${window.company} Technology and the registered copyright owner and affiliated companies of this product, except for the following situations:`,
  t37: `1. Sharing with explicit consent: After obtaining your explicit consent, we will share your personal information with other parties.`,
  t38: `2. We may share your personal information with the public in accordance with laws and regulations or mandatory regulations of government regulatory authorities.`,
  t39: `3. Sharing with authorized partners: Some of our services will be provided by authorized partners solely for the purposes stated in this policy. We may share some of your personal information with our partners to provide better customer service and user experience. We will only share your personal information for legitimate, legitimate, necessary, specific, and clear purposes, and only share personal information necessary for providing services. Our partners have no right to use the shared personal information for any other purpose.`,
  t40: `At present, our authorized partners include the following types:`,
  t41: `1) Suppliers, service providers, third-party agents, and other partners. We send information to suppliers, service providers, third-party agents, and other partners worldwide who support our business, including providing technical infrastructure services, analyzing the usage of our services, measuring their effectiveness, providing customer services, facilitating payments, or conducting academic research and surveys. With your explicit prior consent, we may share your consumption records, subscription records, recharge records, order records, deleted viewing records, and consumption activity consumption records with third-party agents after adopting encryption and anonymization methods. If you do not agree to our sharing of the above information, it will not affect your normal use of this product.`,
  t42: `2) Analysis service data service providers: In order to provide you with better services, we cooperate with third-party data service providers including Youmeng+to predict your feature preferences. In order to ensure the security of your data, this prediction is provided in the form of exponential scores, and data analysis is conducted under strict de identification security measures, so as not to disclose your personal information.`,
  t43: `3) Authorized partners for advertising and analysis services. Unless we obtain your permission, we will not share your personal identification information (referring to information that can identify your identity, through which you can be contacted or identified) with partners who provide advertising and analysis services. But we may share your personal information with authorized partners in advertising and analysis services after anonymization, including: network identification information (IP address), software version number, software package name, software installation list, device connection information (telecom operator, network environment), device attribute information (device manufacturer, device model, operating system version, IMEI, OAID, IMSI, sensor, device serial number), to help them improve the effective reach rate of advertisements without recognizing your individual.`,
  t44: `The permissions required for third-party SDK access services. Our products and services may include third-party products and services, as well as links to third-party websites. When you use these products or services, you may also collect your information. After the user has agreed to authorize, personal information will be sent to a third party. When the third party's identity changes, the user's consent will be obtained again through a pop-up window, and we will share personal information with the third party.`,
  t45: `（2） Transfer`,
  t46: `We will not transfer your personal information to any company, organization, or individual, except in the following circumstances:`,
  t47: `1. Transfer with explicit consent: After obtaining your explicit consent, we will transfer your personal information to other parties;`,
  t48: `2. When it comes to mergers, acquisitions, or bankruptcy liquidation, if it involves the transfer of personal information, we will require the new company or organization that holds your personal information to continue to be bound by this privacy policy. Otherwise, we will require the company or organization to seek your authorization and consent again.`,
  t49: `（3） Public disclosure`,
  t50: `We will only publicly disclose your personal information in the following circumstances:`,
  t51: `1. After obtaining your explicit consent;`,
  t52: `2. Legal disclosure: We may publicly disclose your personal information in legal, procedural, litigation, or mandatory requirements from government authorities.`,
  t53: `（4） The types of personal information that may be involved in external sharing include device identification codes and product version information; The types of personal information that may be involved in public disclosure include watching TV series and viewing duration.`,
  t54: `We are aware of the corresponding legal responsibilities for sharing, transferring, and publicly disclosing personal information to the public, and will take reasonable measures to ensure the security of personal information.`,
  t55: `4、 How do we protect and save your personal information`,
  t56: `（1） Technical protection measures`,
  t57: `We attach great importance to the security of your personal information and will strive to take various reasonable security measures that comply with industry standards to protect your information from leakage, damage, or loss, including but not limited to SSL, encrypted storage of privacy information, and access control in data centers. We will use encryption technology to enhance the security of personal information; We will use trusted protection mechanisms to prevent malicious attacks on personal information; We will deploy access control mechanisms to ensure that only authorized personnel can access personal information.`,
  t58: `（2） Security management system`,
  t59: `We have an industry-leading data security management system centered around the data lifecycle, which enhances the security of the entire system from multiple dimensions such as organizational construction, system design, personnel management, product technology, and personal information security impact assessment.`,
  t60: `We have also implemented strict management measures for employees or outsourced personnel who may come into contact with your information, including but not limited to implementing different permission controls based on their positions, signing confidentiality agreements with them, and monitoring their operations.`,
  t61: `（3） Account protection`,
  t62: `Your account has security protection functions, please keep your account and password information safe. If you find that your account has been stolen or your personal information has been leaked, please contact us in a timely manner so that we can take appropriate measures.`,
  t63: `（4） Information security incident handling`,
  t64: `Despite the aforementioned security measures, please also understand that there are no comprehensive security measures on the information network.`,
  t65: `If a personal information security incident unfortunately occurs, we will inform you in accordance with the requirements of laws and regulations: the basic situation and possible impact of the security incident, the disposal measures we have taken or will take, suggestions for you to independently prevent and reduce risks, and remedial measures for you. We will inform you of the relevant situation of the incident by phone or notification. If it is difficult to inform the individual information subject one by one, we will take reasonable and effective measures to publish an announcement. At the same time, we will also report the handling of personal information security incidents in accordance with regulatory requirements.`,
  t66: `（5） Shelf life`,
  t67: `We only store your personal information in your country. We will take all reasonable and feasible measures to ensure that unrelated personal information is not collected. We will only retain your personal information for the shortest period required by law to achieve the objectives stated in this policy, unless an extension of the retention period is required or permitted by law.`,
  t68: `After your personal information exceeds the retention period, we will delete your personal information or anonymize it in accordance with legal requirements.`,
  t69: `5、 Your rights`,
  t70: `According to relevant laws, regulations, and standards in your country, we guarantee that you exercise the following rights over your personal information:`,
  t71: `（1） Access your personal information`,
  t72: `You have the right to access your personal information through the user center on your own. Please refer to (VI) how we respond to your request, except for exceptions specified by laws and regulations`,
  t73: `（2） Correcting your personal information`,
  t74: `When you discover errors in the personal information we process about you, you have the right to request us to make corrections. How to correct personal information can be found in (6) How we respond to your request.`,
  t75: `（3） Delete your personal information. In the following situations, you can request us to delete your personal information:`,
  t76: `1. Our handling of personal information violates laws and regulations;`,
  t77: `2. We collect and use your personal information without your consent;`,
  t78: `3. Our handling of personal information violates our agreement with you;`,
  t79: `4. You no longer use our products or services, or you have cancelled your account;`,
  t80: `5. We will no longer provide you with products or services.`,
  t81: `How to delete personal information can be found in (VI) How we respond to your request. If we decide to comply with your request, we will also notify the entities that have obtained your personal information from us to request timely deletion, unless otherwise provided by laws and regulations or with your independent authorization.`,
  t82: `When you delete information from our service, we may not immediately delete the corresponding information in the backup system, but we will delete this information during backup updates.`,
  t83: `（4） Revoke consent and disable usage function`,
  t84: `You can change the scope of your authorization for us to continue collecting personal information or revoke your authorization by disabling device functionality. You can also revoke all our authorization to continue collecting your personal information by canceling your account. How to withdraw consent and disable the use function can be found in (VI) How do we respond to your request`,
  t85: `（5） Personalized recommendation management`,
  t86: `1. Manage personalized advertising information recommendations`,
  t87: `Personalized advertising is one of the advertising display technologies, where advertisers make intelligent recommendations based on your preferences within the application. If you do not want to be recommended for personalized advertising, you can choose whether to receive personalized advertising recommendations on the [My] - [System Settings] - [Personalized Advertising Recommendations] switch. After closing, the relevance of the advertisement you see will decrease.`,
  t88: `2. Manage personalized content recommendations`,
  t89: `To ensure that you can watch the content you are interested in, we offer the ability to recommend personalized content. If you do not want to be recommended for personalized content, you can turn off personalized content recommendation by using the [My] - [System Settings] - [Personalized Content Recommendation] switch. After closing, the homepage will not display recommended channels. Switching to selected channels will reduce content relevance.`,
  t90: `（6） Account cancellation`,
  t91: 'You can cancel your account in `My` - `System Settings` - `Cancel Account`. You can cancel your previously registered account at any time, please refer to the `Cancellation Notice` for details.',
  t92: `After canceling your account, we will stop providing you with products or services and delete your personal information, except as otherwise provided by laws and regulations.`,
  t93: `In the following situations, according to legal requirements, we will not be able to comply with your personal information deletion request:`,
  t94: `1. Directly related to national security and national defense security;`,
  t95: `2. Directly related to public safety, public health, and major public interests;`,
  t96: `3. Directly related to criminal investigation, prosecution, trial, and enforcement of judgments;`,
  t97: `4. There is sufficient evidence to indicate that you have subjective malice or abuse of power;`,
  t98: `5. Responding to your request will result in serious damage to your or other individuals or organizations' legitimate rights and interests;`,
  t99: `6. Involving trade secrets.`,
  t100: `（7） In response to your above request`,
  t101: 'You can choose to disable the permissions you wish to disable through the `Settings` function on your phone.',
  t102: 'You can also make a phone call by clicking on `My` - `About Us` - `Contact Customer Service` and then contact customer service to perform user operations such as personal information inquiry, personal information correction, personal information deletion, user account cancellation, and revocation of agreed authorization. To ensure security, you may need to provide a written request or other means to prove your identity. We may first request you to verify your identity before processing your request.',
  t103: `We will provide a response within fifteen working days. If you are not satisfied, you can also appeal by contacting customer service.`,
  t104: `6、 How do we handle children's personal information`,
  t105: `Our products, websites, and services are primarily aimed at adults. We attach great importance to the protection of personal information of children and minors. If you are a minor under the age of 18, before using the relevant services on this platform, you should read and agree to this privacy policy under the supervision and guidance of your parents or other guardians, and use our services or provide information to us with the consent of your guardians.`,
  t106: `For cases where we collect personal information of minors using our products or services with the consent of our parents or guardians, we will only use, share, transfer, or disclose this information when permitted by laws and regulations, explicitly agreed upon by our parents or guardians, or necessary to protect minors. If we find that we have collected personal information of minors without obtaining verifiable parental consent in advance, we will delete the relevant information as soon as possible.`,
  t107: `7、 How to transfer your personal information globally`,
  t108: `At present, we do not transmit or store your personal information across borders. If cross-border transmission or storage is required in the future, we will inform you of the purpose, recipient, security measures, and security risks of the information being exported, and obtain your consent.`,
  t109: `8、 How to update this policy`,
  t110: `After the update of this privacy policy, this platform will display the updated privacy policy and content to you in the form of notifications, pop ups, and website announcements when you log in and version updates, so that you can stay informed of the latest version of this privacy policy in a timely manner. We will strictly follow the privacy policy collection and usage rules disclosed to you in conducting personal information processing activities. If the purpose of personal information use changes, we will obtain your consent again. If you continue to use our services, it means that you agree to accept the revised content of this policy.`,
  t111: `9、 Application of this policy`,
  t112: `All services of this product are subject to this policy.`,
  t113: `When you first log in to this product, we will prompt you to read this policy and obtain your consent. Unless otherwise agreed or required by law, this privacy policy does not apply to products or services linked to other third parties by ${window.company} Technology and the registered copyright owner of this product. Your use of these third-party services (including any personal information you provide to these third parties) will be subject to the terms of these third-party services and privacy policies (rather than this privacy policy). Please carefully read the terms of these third parties for specific provisions. Please protect your personal information properly and only provide it to third parties when necessary.`,

  p1: `This Privacy Policy (referred to as this Policy) was issued and effective on March 15, 2023, and was last revised on April 10, 2023. This Policy is a valid contract between ${window.region} ${window.company} Technology Co., Ltd. (hereinafter referred to as '${window.company} Technology', '${window.company}' or 'us') and the user (also referred to as 'you') regarding privacy protection related matters of the client product Hippo Theater (hereinafter referred to as 'this Product'). By clicking to confirm this agreement or choosing to accept it in any other way, you have reached an agreement with ${window.company} Technology and ${window.company} and agree to accept all the following provisions of this policy.`,
  p2: `${window.region} ${window.company} Technology Co., Ltd. is the registered copyright owner of this product and enjoys the copyright of this product in accordance with the law.`,
  p3: `This product is jointly operated by ${window.company} Technology and ${window.company} to provide you with services and enjoy rights, fulfill obligations, and assume legal responsibilities for you in accordance with the law.`,
  p4: `This policy will help you understand the following content:`,
  p5: `1. How do we collect and use your personal information`,
  p6: `2. How do we use cookies and similar technologies`,
  p7: `3. How do we share, transfer, and publicly disclose your personal information`,
  p8: `4. How do we protect your personal information`,
  p9: `5. Your rights`,
  p10: `6. How do we handle children's personal information`,
  p11: `7. How to transfer your personal information globally`,
  p12: `8. How to update this policy`,
  p13: `9. Application of this policy`,
  p14: `10. Dispute resolution`,
  p15: `${window.company} Technology and the registered copyright owner of this product attach great importance to the protection of personal information. When users (referred to as 'you') use our products or services, we may collect and use your relevant information. Once you choose to use our products or services, it means that you acknowledge and accept the existing content of these terms and any updates that may be made at any time. If you do not agree, the service of using this product should be terminated. Please carefully read and understand this Privacy Policy before using our products. `,
  p16: `1、 Please carefully read and understand all rights and limitations stipulated in this agreement. Once you install, copy, download, access or otherwise use the product, it will be deemed as acceptance of this agreement, and you agree to be bound by the terms of this agreement. You must ensure that you download or use this software product from the designated website and obtain it from legally distributed media. Otherwise, we will not be responsible for any potential risks and losses caused by this. We remind you that if you are under 18 years old or do not have full capacity for civil conduct, please read this agreement with your legal guardian and pay special attention to the terms related to the use of this service by minors. Otherwise, we shall not be liable for any adverse consequences that may arise from subsequent registration, use of this service, or payment recharge, except as expressly provided by law. We have the right to terminate the user agreement between both parties upon becoming aware of such circumstances.`,
  p17: `2、 Declaration of Rights`,
  p18: `1. ${window.region} ${window.company} Technology Co., Ltd. is the registered copyright owner of this software and enjoys the copyright of this software in accordance with the law.`,
  p19: `2. Without the written consent of ${window.company} Technology and the registered copyright owner of this software, users are not allowed to independently implement, utilize, transfer or license any third party to implement, utilize, transfer the aforementioned software copyright and other intellectual property for any profit or non-profit purposes. ${window.company} Technology and the registered copyright owner of this software reserve the right to pursue the aforementioned unlicensed behavior.`,
  p20: `3. Prohibition of Reverse Engineering, Reverse Compilation, and Reverse Assembly: Users are not allowed to reverse engineer, decompile, or disassemble this software product, and are not allowed to modify any resources compiled within the program files. Except as expressly permitted by laws and regulations, users must comply with the limitations of this agreement.`,
  p21: `4. Component segmentation: This software product is licensed for use as a whole, and users are not allowed to use each part separately for any purpose.`,
  p22: `5. Individual Authorization: If commercial sales, copying, distribution, including but not limited to software sales, pre installation, bundling, etc. are required, separate written authorization and permission must be obtained from the software copyright owner.`,
  p23: `6. Reserved Rights: All other rights not expressly authorized in this agreement shall remain the property of ${window.company} Technology, and users must obtain written consent from ${window.company} Technology when using other rights.`,
  p24: `7. The video content provided by this software only represents the author's own position and viewpoint, and is not related to ${window.company} Technology and the registered copyright owner of the software. The author shall bear all legal responsibilities.`,
  p25: `3、 User Instructions`,
  p26: `1. Software functions: search and watch short dramas; Recharge, download, and purchase short dramas; Watch and save the viewing progress.`,
  p27: `2. Regarding payment for digital content: All revenue generated from  user payments will be collected by ${window.company} Technology after deducting the fees charged by third-party payment institutions according to regulations. The digital content within this software consists of free content and paid content, which are divided into limited time free content and permanent free content. ${window.company} Technology has the right to decide on the standards and charging methods of the services provided, and provide prompts on the relevant service pages regarding the pricing standards. Users have the right to choose whether to pay the corresponding fees to continue using the paid services provided by this software. If you are a minor under the age of 18 (especially a minor under the age of 14), you should recharge with the consent of your legal guardian. After recharging on this software, users will receive corresponding viewing points, which can be used to purchase the paid services provided by this software. Your agreement to this agreement shall be deemed as your agreement to grant ${window.company} Technology the authorization to automatically purchase the content of the next episode and bulk purchase the content of the series for your paid service. Once a user successfully recharges (including minors who have successfully recharged with the consent of their guardians), they shall not demand a refund of all or part of the recharged amount for illegal reasons, except in cases of force majeure, agreement agreements, or relevant laws and regulations.`,
  p28: `3. Software modification and upgrade: The copyright owner of this software reserves the right to provide users with modified and upgraded versions of this software at any time. After the user selects and confirms, the software will be upgraded and updated, and corresponding data traffic fees will be generated by using the operator's network, which will be charged by the operator.`,
  p29: `4. Users should use this software in compliance with laws and this agreement. Users are not authorized to engage in the following behaviors, including but not limited to:`,
  p30: `(1) Delete or change all rights management electronic information on this software;`,
  p31: `(2) Intentionally avoiding or damaging the technical protection measures taken by the copyright owner to protect the copyright of this software;`,
  p32: `(3) Using this software to mislead and deceive others;`,
  p33: `(4) Violating national regulations by deleting, modifying, adding, or interfering with the functions of computer information systems, resulting in the malfunction of computer information systems;`,
  p34: `(5) Unauthorized access to computer information networks or use of computer information network resources;`,
  p35: `(6) Unauthorized deletion, modification, or addition of computer information network functions;`,
  p36: `(7) Unauthorized deletion, infiltration, modification, or addition of data and application programs stored, processed, or transmitted in computer information networks;`,
  p37: `(8) Disrupting the normal operation of this software system or website, intentionally spreading destructive programs such as computer viruses;`,
  p38: `(9) Any other behavior that endangers computer information network security;`,
  p39: `(10) Other abnormal usage behaviors.`,
  p40: `5. For this software product downloaded from non designated sites or obtained from illegally distributed media, ${window.company} Technology cannot guarantee whether the software is infected with computer viruses, whether there are disguised Trojan programs or hacker software hidden. The use of such software by users may lead to unpredictable risks. It is recommended that users do not download, install, or use it easily. ${window.company} Technology and the registered copyright owner of this software do not assume any legal responsibility arising from this.`,
  p41: `6. Privacy protection`,
  p42: `${window.company} Technology promises to comply with the Privacy Policy (also known as the 'Privacy Agreement') and applicable laws, and has formulated the following four privacy protection principles to guide us in dealing with issues related to user privacy and information in our products:`,
  p43: `(1) Before collecting your information, we will clearly inform you of the method, scope, and purpose of information collection and obtain your consent. We use the information we collect to provide users with valuable products and services only. We will never exceed the scope or frequency of collecting your personal information, such as contacts, location, ID card, facial information, etc., and will never collect information that is not necessary or has no reasonable application scenarios for this software service;`,
  p44: `(2) Develop products that comply with privacy standards and privacy practices. Without the user's consent, personal information such as device identification information, product browsing history, search usage habits, and commonly used software application lists shall not be shared or used with other application software. Never use collected personal information such as user searches, browsing history, usage habits, etc. for targeted promotion or precision marketing without informing or prominently indicating to users, and do not provide users with the option to disable this function;`,
  p45: `(3) Transparent collection of personal information;`,
  p46: `(4) Make every effort to protect the user information we have access to.`,
  p47: `Based on this, you agree to authorize ${window.company} Technology to use your user profile picture and nickname, so that we can better serve you.`,
  p48: `7. After becoming a VIP member, during the membership period, you can watch all short drama content on this platform.`,
  p49: `8. During the installation and operation of this software, ${window.company} Technology will never request any permissions unrelated to the user's current usage scenario. Moreover, after the user explicitly refuses the permission application, they should never frequently apply to enable permissions unrelated to the current service scenario, such as contact list, location, SMS, recording, camera, etc., in order to force the user to agree to the activation of permissions unrelated to this software. When users do not use relevant functions or services, they shall not apply for permission to enable user contacts, location, SMS, recording, camera, etc. in advance, or apply for permission to enable contacts, location, SMS, recording, camera, etc. beyond the business functions or services of this software product. The clipboard permission is only used in scenarios where the user chooses to copy the customer service phone number. If the user does not choose to copy, ${window.company} Technology will never apply to the user to enable clipboard permission.`,
  p50: `9. Users have the right to cancel their personal account information. After the user cancels their personal account information, ${window.company} Technology will delete the personal account information stored by the user in this software (see relevant cancellation notices for details).`,
  p51: `10. When you reinstall the mobile system or clean up mobile application software, all your user information will be lost, including but not limited to: personal account information, records of searching or browsing dramas, downloaded or purchased dramas, viewing records, viewing progress, membership benefits, and at the same time, all views in your personal account will be reset to zero. You are aware that this is determined by the characteristics of the product, but you can avoid losing your relevant user information when reinstalling the mobile system or cleaning up mobile applications by associating your personal account with a phone number. When you fail to associate your personal account with a mobile phone number, in the event of user information loss, ${window.company} Technology and the copyright owner of this software will not be held responsible for this.`,
  p52: `11. You are not allowed to engage in illegal activities or engage in abnormal consumption in any way on this software platform or by exploiting system vulnerabilities, program defects, human operation errors, etc. You are not allowed to use malicious software or any other means to disrupt the normal operation of this software platform. You are not allowed to engage in any activities that violate the principle of good faith. Otherwise, once you are recognized by this software platform as having the aforementioned behavior, ${window.company} Technology has the right to directly freeze or cancel your account without prior notice and will not return the account balance. All losses arising from this will be borne by you. In addition, ${window.company} Technology still reserves the right to pursue your legal responsibility in accordance with the law. If your actions cause losses to ${window.company} Technology, you should compensate for all the losses caused to ${window.company} Technology. If you are suspected of committing a crime, ${window.company} Technology has the right to transfer the relevant clues to the judicial department for processing.`,
  p53: `We attach great importance to the protection of personal information and legitimate rights and interests of minors, and actively promote their healthy growth. Minors (especially those under the age of fourteen) who use the services under this agreement without the accompaniment and consent of their parents (guardians) shall be promptly notified by their parents (guardians) upon discovery. We will assist their parents (guardians) in handling their registered accounts and accounts to ensure that their guardianship responsibilities and obligations are fulfilled.`,
  p54: `4、 Protection of intellectual property rights for users and third parties`,
  p55: `1. During the use of the services included in this software, users may need to provide content to ${window.company} Technology through various means such as posting comments. All original content published by users on this software is owned by the user themselves. The comments posted by users only represent their opinions and are not related to the registered copyright owner of this software or ${window.company} Technology.`,
  p56: `2. Users are not allowed to infringe on intellectual property rights, including the copyrights of others, as well as other rights. In case of any legal disputes arising from the content published by the user, the responsibility shall be borne by the user themselves.`,
  p57: `3. For any content published by the user on this software, the user agrees to authorize ${window.company} Technology to have a free, permanent, and non exclusive right to use all copyrights worldwide. The user licenses ${window.company} Technology to take separate legal action against any infringement by any party (including but not limited to litigation, reporting, issuing lawyer letters, etc.).`,
  p58: `4. If the user's comments infringe on the copyright or other rights of a third party or violate legal provisions, after the third party raises objections or is discovered through the content review process, ${window.company} Technology has the right to delete the relevant content and has the right to pursue or assist the third party in pursuing the user's legal responsibility.`,
  p59: `5. Without written permission from ${window.company} Technology, any entity is prohibited from using any crawler program, spider program, anthropomorphic program, infiltration technology, hacker program, and other automatic devices, or manual programs to invade, read, copy, store this software or any content contained therein, or engage in any illegal or dishonest activities. Otherwise, ${window.company} Technology has the right to directly freeze or cancel your account without notifying you, and has the right not to return the account balance. All losses incurred as a result will be borne unilaterally by you. In addition, ${window.company} Technology still has the right to hold you legally responsible to compensate for all losses caused to ${window.company} Technology due to your above-mentioned actions, in order to effectively protect the legitimate rights of ${window.company} Technology.`,
  p60: `5、 Content Copyright Statement`,
  p61: `${window.company} Technology promises to abide by and respect the protection of copyright owners under current Chinese laws, regulations, and other normative documents. As the rights holder, when you discover that the provided content infringes on your legitimate rights and interests, you should first send us a 'rights notice' according to the processing process cs{'@'}ishugui.com Or call customer service at 400-118-0066. We will take measures to remove relevant content or disconnect or block related links in accordance with Chinese laws and regulations and government normative documents. After taking the aforementioned measures, you shall not hold us and the registered copyright owner of this software liable for any legal liabilities.`,
  p62: `6、 Disclaimer and Limitation of Liability`,
  p63: `1. The user confirms that they are aware of all the functions of this software and the necessary operations to implement each function of this software. They voluntarily choose to use this software and related services according to their own needs. The risks and all consequences of using this software and related services will be fully borne by you in accordance with the law. The registered copyright owner of this software and ${window.company} Technology shall not be held responsible.`,
  p64: `2. This software has undergone detailed testing, but we cannot guarantee full compatibility with all software and hardware systems, nor can we guarantee that this software is completely error free. If there are incompatibilities and software errors, users can call customer service hotline 400-118-0066 to report the situation and obtain technical support. If compatibility issues cannot be resolved, users can delete this software.`,
  p65: `3. To the maximum extent permitted by applicable law, the registered copyright owner of this software and ${window.company} Technology shall not be liable for any damages and risks arising from your use or inability to use this software, including but not limited to direct or indirect personal damage, loss of commercial profits, trade interruption, loss of commercial information, or any other economic losses.`,
  p66: `4. The registered copyright owner of this software and ${window.company} Technology will not bear any responsibility for any loss caused by information damage or loss, mobile phone system problems or any other force majeure reasons due to telecommunications system or Internet network failure, mobile phone failure or virus program operation.`,
  p67: `5. If the user violates the provisions of this agreement and causes damage to the registered copyright owner of this software and ${window.company} Technology, ${window.company} Technology has the right to take measures including but not limited to interrupting the user's usage license, stopping the provision of services, restricting use, and pursuing legal responsibilities.`,
  p68: `7、 Legal and Dispute Resolution`,
  p69: `1. This agreement is governed by the laws of your country.`,
  p70: `2. Any disputes arising from or related to this agreement shall be resolved through friendly consultation between the parties; If the negotiation fails, either party has the right to submit the dispute to the arbitration commission for arbitration.`,
  p71: `8、 Other terms`,
  p72: `1. If any provision of this Agreement is completely or partially invalid or unenforceable for any reason, or violates any applicable law, such provision shall be deemed deleted, but the remaining provisions of this Agreement shall remain valid and binding.`,
  p73: `2. ${window.company} Technology has the right to modify this agreement at any time in accordance with changes in relevant laws and regulations, as well as adjustments to the company's operating conditions and strategies. The revised agreement will be published on the website and attached to the new version of the software. When disputes arise, the latest agreement text shall prevail. If the user disagrees with the changes, they can delete the software themselves. If the user continues to use this software, it shall be deemed that you have accepted the changes to this agreement.`,
  p74: `3. ${window.company} Technology has the right to interpret and modify this agreement to the maximum extent permitted by law.`,

  login_first: `请登录`,
  sessionTimeout: `请重新登录`,
  unknown_err: `未知错误`,
  way: `方式`,

  starCardPlan: `M-Card`,
  starCardPrivate: `M-Card权益`,
  starCardIntroduction: `M-Card是M-Plan推广系统的权利证明。 M-Card分为M-Card和M-Card Pro。成为M卡用户即可获得奖励。`,

  banQuanZhongChou: `版权`,
  banQuan1: `版权收益达130%以上。`,
  banQuan2: `版权收益达150%以上并有更多奖励。`,

  enterPhoneNumber: `请输入手机号码`,
  enterRightNumber: `请输入正确手机号码`,
  pwdNotNull: `验证码不能为空`,
  checkAgreement: `请先同意隐私协议和用户协议`,
  second: `秒`,
  sendSuccess: `发送成功`,
  codeValid: `获取验证码`,

  promoteCount: `M-Team人数`,
  today: `今日`,
  promoteList: `推广列表`,
  nickName: `昵称`,
  level: `等级`,
  register: `注册`,
  noMore: `没有更多内容`,
  page: `页`,
  drawout: `提现`,
  logining: `载入中`,
  shortMoive: `短剧`,
  otherLogin: `其他方式登录`,

  uploading: `上传中`,
  timeout: `请检查网络连接`,
  uploadLimitOne: `您只能上传一张图片`,

  unit: `${window.currency}`,

  drawoutNotice: `<p>1.請填寫真實賬戶資料。因帳戶資訊錯誤而造成的損失由使用者自行承擔。</p>
    <p>2.提款需要審核。提款可能會延遲一段時間。</p>
    <p>3.最低提款金額 ${window.currency}$extractSingleMin.</p>
    <p>4.最大提款金額 ${window.currency}$extractSingleMax.</p>
    <p>5.提款費用 $extractChargeRate,將從提款金額中扣除。</p>
    <p>6.您每天可以申請 $extractDailyCount 次提現。</p>
    <p>7.提現將在一天內收到。</p>`,

  submitItem: `提交`,
  caresure: `提示`,
  server_bank: `銀行`,
  server_bankCardNo: `銀行賬號`,
  server_bankCardHolder: `賬戶名`,
  server_email: `E-mail`,
  server_phone: `電話`,

  pleaseInputPwd: `請輸入密碼`,
  useCodeLogin: `驗證碼登入`,
  usePwdLogin: `密碼登入`,
  pwdLimitTip: `密碼長度應為6-20個字符`,
  pleaEnterInviteCode: `請輸入邀請碼`,
  setPwd: `設定登入密碼`,
  pwdPlaceholder: `請確認密碼長度為6-20個字符`,
  optSuccess: `成功`,

  Balance: `餘額`,
  WithdrawAmountTip: `提現金額不能為空`,
  WithdrawAmountTipMin: `您的錢包餘額低於最低提領金額`,
  WithdrawAmountTipDecimal: `提款金額精確到小數點後兩位`,
  TransferAmountTip: `轉帳金額不能為空`,
  TransferAmountTipMin: `您的錢包餘額低於最低轉帳金額`,
  TransferAmountTipDecimal: `轉帳金額精確到小數點後兩位`,
  Submit: `提交`,

  BankOfDeposit: `銀行`,
  BankOfDepositTip: `銀行名稱不能為空`,
  BankCardNumber: `銀行卡號`,
  BankCardNumberTip: `銀行卡號不能為空`,
  userName: `賬戶名`,
  userNameTip: `賬戶名不能為空`,

  TransferUserID: `User ID`,
  TransferUserIDTip: `User ID不能為空`,
  ReTransferUserID: `確認User ID`,
  ReTransferUserIDTip: `User ID不能為空`,
  TransferUserIDTips: `兩次輸入的User ID不一致`,

  VerificationCode: `驗證碼`,
  VerificationCodeTip: `驗證碼不能為空`,
  GetVerificationCode: `獲取驗證碼`,

  Precautions: `提示`,
  PrecautionsTip1: `1.请填写真实账户资料。因账户信息错误而造成的损失由用户自行承担。`,
  PrecautionsTip2: `2.提款需要审核。提款可能会延迟一段时间。`,
  PrecautionsTip3: `3.最低提款金额 1.`,
  PrecautionsTip4: `4.最大提款金额 1.`,
  PrecautionsTip5: `5.提款费用 1, 将从提款金额中扣除。`,
  PrecautionsTip6: `6.您每天可以申请1次提现。`,
  PrecautionsTip7: `7.提现将在一天内收到。`,

  confirmPay: `确认支付 $y`,
  goPay: `立即支付 $y `,
  couponState: `类型不匹配`,
  couponNo: `兑换码不可用`,
  coupinErr: `兑换码不正确`,
  couponUse: `折扣金额 $y`,
  BalanceDeductions: `钱包余额支付 $y`,
  ExchangeStarCard: `兑换M-Card`,
  EnterMembershipTips: `兑换一定时间的M-Card或M-Card Pro`,
  EnterMembership: `兑换$y天$x`,
  ExchangeNotUsed: `兑换码尚未使用`,
  ExchangeUsed: `兑换码已被使用`,
  noExchange: `兑换码不存在`,
  noRedemptionTips: `请输入兑换码`,
  PleaseAmount: `请选择参与金额:`,
  PaymentAgreement: `付款即视为您同意`,
  DramaAgreement: `M-Card服务协议`,

  setPayment: `选择支付方式 `,
  saveInfo: `保存信息 `,
  myBuy: `买入`,
  mySell: `卖出`,
  placeOrder: `上架订单`,
  whole: `全部`,
  myOrder: `我的订单`,
  anonymousUser: `匿名用户`,
  deal: `交易`,
  sell: `卖出`,
  integral: `Pt`,
  demand: `要求`,
  minute: `分钟`,
  sellOut: `卖出`,
  purchase: `买入`,
  pendingOrders: `待处理订单`,
  confirmOrder: `确认订单`,
  choosePaymentMethod: `选择支付方式`,
  back: `返回`,
  orderGenerated: `生成订单`,
  please: `请`,
  paymentInformation: `确认买家付款信息`,
  reportExceptions: `报告异常`,
  sellerToConfirm: `等待卖家确认收款`,
  ordersCannotStage: `此阶段无法取消订单`,
  orderCompleted: `订单已完成`,
  contactustomer: `如有异议请联系客服调解`,
  customerServiceMediation: `客户异议调解`,
  confirmCompletion: `确认完成`,
  PleaseWaitPayment: `请等待买家付款`,
  reportCorrect: `是否上报订单异常？`,
  addMethod: `新增付款方式`,
  selectType: `选择类型`,
  sellPoints: `出售积分`,
  sellUsers: `将账户中的积分出售给其他用户`,
  purchasePoints: `购买积分`,
  canPointsUsers: `可以向其他用户购买积分`,
  messageBoard: `留言板`,
  you: `你`,
  reply: `回复`,
  enterBelow: `请在下面输入回复信息`,
  viewDetails: `查看详情`,
  paymentMethod: `支付方式`,
  addPaymentInformation: `新增付款信息`,
  paymentDeadline: `付款期限`,
  totalAmount: `总金额`,
  price: `单价`,
  tradingPrecautions: `交易注意事项`,
  afterQuantity: `1. 确认订单后，您的积分将根据订单数量被锁定。`,
  confirmCancelled: `2. 请在规定时间内确认交易完成，否则将自动取消。`,
  cancellationOrder: `取消订单`,
  CancelOrder: `取消订单`,
  transactionDetails: `交易详情`,
  myPendingOrder: `我的待处理订单`,
  orderType: `订单类型`,
  myTransaction: `我的交易`,
  orderStatus: `订单状态`,
  buyPoints: `买入积分`,
  release: `发布`,
  locking: `锁定`,
  end: `结束`,
  removeFromShelves: `从上架列表移除`,
  establish: `创建`,
  approachingTimeout: `即将超时`,
  overtime: `超时`,
  buyerConfirmed: `买家已确认`,
  sellerConfirmed: `卖家已确认`,
  successfulTrade: `交易成功`,
  createOrder: `创建买单`,
  createSellOrder: `创建卖单`,
  enterPurchaseQuantity: `输入购买数量`,
  enterSale: `输入卖出数量`,
  pointsBalance: `积分余额`,
  setMaxQuantity: `设定最大数量`,
  setPurchasePrice: `设定购买价格（总金额）`,
  setSalePrice: `设定销售价格（总金额）`,
  rangePrice: `积分单价范围`,
  confirmPeddingOrder: `确认上架`,
  pointsRange: `积分单价超出范围`,
  orderSuccessfully: `订单上架成功`,
  OrderErr: `订单错误`,
  OrderOverTime: `订单超时`,
  CustomeRservice: `如有异议请联系客服调解`,
  UpdataPedding: `修改上架订单`,
  CustomerService: `异议调解`,
  WaitingPayment: `等待付款`,
  Collectionpayment: `等待付款`,
  ConfirmCompletion: `确认完成`,
  ContactCustomer: `联系客户进行调解`,
  NumberPending: `今日待处理订单数量`,
  Edit: `编辑`,
  Create: `创建`,
  Bill: `法币`,
  SellBill: `出售法币`,
  PendingOrders: `待处理订单`,
  Reason: `原因`,
  Kong: `无数据`,
  USDTAmout: `提现为USDT后，金额为$slot`,

  //new
  WALLET: '钱包',
  THIRD_PAY: '三方支付',
  enterPassword: `请输入密码`,
  enterSecondPassword: `请再次输入密码`,
  confirmPassword: `两次密码不一致`,
  registerIn: `立即注册并登录`,
  LoginCode: `验证码登录`,
  LoginPassword: `密码登录`,
  goLogin: `登录`,
  goRegister: `注册`,
  ClearAccount: `您确定要删除您的账户吗？`,

  Using: `使用`,
  SwitchAccount: `切换账户`,
  AddAcount: `新增账户`,
  Finish: `完成`,

  // newHome
  AppDesc: `创新短视频分享平台`,
  AppIntroduce: `MovSee 是一个创新的短影片分享平台，用户可以通过观看短剧并参与互动来赚取收益。平台结合了娱乐和获利模式，吸引了大量用户。`,
  DownloadNow: `立即下载`,
  SignIn: `登录`,
  MarketBackground: `市场背景`,
  MarketDesc: `随着短视频用户数量的激增，市场对短剧的需求越来越大。根据统计，在全球 10 亿短影用户中，50.4% 的用户曾观看长达 3 分钟以内的微短剧。产业预测显示，到 2023 年，短剧市场规模将达到 28 亿至 42 亿美元。预计到 2024 年，市场仍将保持快速增长。`,
  ProductFeatures: `产品特点`,
  AppFeature1: `1.多元化内容: `,
  AppFeatureDesc1: `MovSee 提供丰富的短剧内容，涵盖喜剧、爱情、悬疑等多种类型，以满足不同用户的需求。`,
  AppFeature2: `2.收益模式: `,
  AppFeatureDesc2: `用户通过观看、评论和分享短剧可以赚取收益。平台通过用户的互动行为给予现金奖励，形成独特的收益模式。`,
  AppFeature3: `3.互动社群: `,
  AppFeatureDesc3: `MovSee 的互动功能使得用户可以点赞、评论、分享短剧，增强了用户间的互动，形成了一个活跃的社群。`,
  AppFeature4: `4.个性化推荐: `,
  AppFeatureDesc4: `平台使用智慧推荐算法，根据用户的兴趣与喜好推荐对应的短剧，提升用户的观影体验与参与度。`,
  CompetitiveAdvantages: `核心竞争优势`,
  CompetitiveAdvantages1: `1.开放版权的推广与发行: `,
  CompetitiveAdvantages1_1: `MovSee 发掘了开放版权的商业价值，帮助未开发的内容通过平台进行推广并实现利润增长。`,
  CompetitiveAdvantages2: `2.自制高品质短剧: `,
  CompetitiveAdvantages2_1: `MovSee 增加了自制短剧的投资，致力于提升平台内容的质量。自制内容不仅能确保内容独特性，还能提高用户留存率。`,
  CompetitiveAdvantages3: `3.短剧演员 IP 管理: `,
  CompetitiveAdvantages3_1: `通过对微短剧演员的 IP 进行开发，平台利用影响者效应提高知名度，增加用户参与，最终实现流量转化。`,
  CardTitle1: `产业链布局`,
  CardDesc1: `MovSee 建构了完善的上下游产业链，内容源自版权方和平台方，经过中游的生成，由下游的分发管道和串流媒体代理进行推广。通过这完整的产业链布局，平台能够更有效率地进行内容生产与分发。`,
  CardTitle2: `M-Card系统`,
  CardDesc2: `MovSee 推出了 M-Card作为用户参与 MovSee推广系统的凭证。 M-Card分为M-Card和M-Card Pro两种，持卡用户都可以参与平台的收益分成，获得现金奖励。`,
  CardTitle3: `版权投资`,
  CardDesc3: `该平台提供了版权推广投资机会，允许用户投资一部分短剧的版权推广，赚取版权收益。平台会帮助这些投资人将资金用于短剧的推广和发行，而收益则随着推广进度波动。`,
  CardTitle4: `社交与团队收益`,
  CardDesc4: `MovSee 引入了社交和团队收益概念，用户可以通过组建团队并利用社交网络进行推广，从而最大化团队收益。团队协作和推广不仅能提高收益，还可以解锁额外的奖励和奖金。`,
  UserFeedback: `用户反馈`,
  UserFeadbackDesc: `MovSee 平台受到了用户的一致好评，以下是部分用户的反馈`,
  Career1: `学生`,
  Career2: `老师`,
  Career3: `程序员`,
  Career4: `司机`,
  UserTalk1: `MovSee 让我一边娱乐一边赚钱，简直太棒了！`,
  UserTalk2: `· 短剧内容丰富有趣，收益模式也很吸引人。`,
  UserTalk3: `MovSee 的个人化推荐功能让我总是能找到喜欢的短剧。`,
  UserTalk4: `·互动性强，让我认识了许多新朋友。`,
  Platfrom: `平台愿景`,
  PlatformDesc: `MovSee 的愿景是通过不断优化内容质量、提升用户体验、扩展用户基础，保持平台的高增长率，并通过多元化的变现策略实现可持续的盈利。`,
  Movshot: `MovSee`,
  Contact: `联系我们`,
  ContactDesc: `我们是一个谦虚的团队，很乐意听到您的想法、反馈和问题。`,
  Message: `消息...`,
  EmailErr: `E-mail格式错误`,
  InputInvite: `请输入邀请码（选填）`,
  FormErr: `请填写完整的表格`,
  FormDesc: `请输入至少10个字符`,
  noPaymentChannel: `无支付通道`,
  pleaSelectCoinType: `请选择币种类型`,
  overDelete: `已取消`,
  registerSuccess: `注册成功`,
  WebVersion: `网页版本`,

  AppDescPro: 'Web3 AI + 短剧矩阵',
  AppIntroducePro:
    'MovSee 是一个创新的短视频分享平台，用户可以观看引人入胜的短剧并通过参与获得奖励。通过整合 Web3 技术，该平台提供沉浸式的去中心化创作和分发体验，推动短视频行业的演变。',
  MarketBackgroundPro: '关于 MovSee',
  MarketDescPro:
    'MovSee 结合区块链和 AI 技术，提供透明、自由的创作和互动环境。',
  ProductFeaturesPro: '产品亮点',
  AppFeaturePro1: '1. 获利模式：',
  AppFeatureDescPro1: '用户可以通过观看、评论和分享短剧来获得真实奖励。',
  AppFeaturePro2: '2. 互动社区：',
  AppFeatureDescPro2: '喜欢、评论和分享等功能促进了活跃的社区氛围。',
  AppFeaturePro3: '3. 个性化推荐：',
  AppFeatureDescPro3: '智能算法根据用户兴趣推荐短剧，提升观看体验。',
  AppFeaturePro4: '4. 多样化内容：',
  AppFeatureDescPro4: '各种短剧，包括喜剧、浪漫、悬疑等，满足多样的观看偏好。',
  CardTitlePro1: 'M 卡计划',
  CardDescPro1:
    'M 卡是 MovSee Pro 推广系统中的关键凭证，为用户提供各种获利和奖励机会。用户可以选择试用或高级 M 卡，获得现金奖励和额外特权。',
  CardTitlePro2: '社交和团队收益',
  CardDescPro2:
    'MovSee 还引入了社交网络和基于团队的获利模式。用户可以组建团队，通过分享和推广最大化收益，有效提升用户参与度和平台的盈利潜力。',
  CardTitlePro3: '全球短剧生态系统',
  CardDescPro3: 'MovSee 将全球的创作者和观众聚集在一起，共同塑造短剧的未来。',
  PlatfromPro: '加入我们',
  PlatformDescPro:
    'MovSee 热情邀请创作者、观众和投资者加入我们，共同重塑短剧的未来。',
  EntryTitle1: '创新的 Web3',
  EntryTitle2: '短剧体验',
  EntryContent:
    '利用 Web3 的去中心化和价值转移优势，MovSee 正在改变短视频行业。',
  CompetitiveAdvantagesPro1: '1. 去中心化创作和粉丝经济：',
  CompetitiveAdvantagesPro1_1:
    '支持去中心化创作，为用户提供新的参与和投资机会。',
  CompetitiveAdvantagesPro2: '2. 生态系统激励和跨部门合作：',
  CompetitiveAdvantagesPro2_1:
    '通过空投、版权保护和资产整合等机制，MovSee 创造更多用户获利的机会。',
};
